export function getAppConfig() {
	const isLocal = process.env.IS_LOCAL === '1';

	// Defaults
	/*
	const env = process.env.ENVIRONMENT || "dev";
	const dihUrl = process.env.DIH_URL;
	const hostResolverUrl = process.env.HOST_RESOLVER;
	const username = process.env.MERCHANT_USERNAME || "websdk";
	const password = process.env.MERCHANT_PASSWORD || "testing";
	const clientId = process.env.MERCHANT_CLIENT_ID || "10190"
	const accountNr= process.env.MERCHANT_ACCOUNT_NUMBER || "101900"
	*/

	const env = "uat";
	const dihUrl = "https://rtxchange.dih.uat-01.cellpointmobile.net";
	const hostResolverUrl = "rtx.uat-01.cellpointmobile.net";
	const username = "rtxchange";
	const password = "testing";
	const clientId = "10103";
	const accountNr= "101030";

	return {
		serverPort: 8080,
		framePath: isLocal ? 'http://localhost:5222/' : `https://websdk.${env}.cpm.dev`,
		siteDomain: isLocal ? 'localhost:5001' : `websdk-demo.${env}.cpm.dev`,
		// note the .proxyrc file has a duplicate of this line because it's not compatible with mjs
		dihAPIUrl: dihUrl || `https://${username}.dih.${env}.cpm.dev`,
		hostResolver: hostResolverUrl || `${username}.mesb.${env}.cpm.dev`,
		merchant: {
			accountNr: `${accountNr}`,
			clientId: `${clientId}`,
			username: `${username}`,
			password: `${password}`
		}
	}
}
