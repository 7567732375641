import { getAppConfig } from '../config.mjs'

const appConfig = getAppConfig();

async function init() {
  const initResp = await (await fetch("/init_payment")).json();
  console.log("Transaction id: %s", initResp.transaction.id );
  console.log("Order No: %s", initResp.transaction.order_no );
  return {
    txRef: initResp.transaction.id,
    authToken: initResp.access_token,
    debug: true,
    hostResolver: appConfig.hostResolver,
    framePath: appConfig.framePath,

    meta: {
      clientId: initResp.client_config.id,
      account: initResp.client_config.accountNr,
      language: 'us', // ignore type
      platform: "Web SDK",
      version: "0.0.1"
    },

    amount: {
      value: initResp.transaction.amount.value,
      country: initResp.transaction.amount.country,
      currency: initResp.transaction.amount.currency,
    },

    styles: {
      input: {
        fontFamily: "tahoma,sans-serif"
      }
    },
    cardsMeta: initResp.cards.card.slice(2,6)// Temporary fix for demo...
  }
}

document.addEventListener("DOMContentLoaded", async function() {
  const CellPoint = window.CellPoint;
  const config = await init();

  (document.querySelector('.cc-form')).style.display = "block";
  (document.querySelector('.init-demo-form')).style.display = "none";

  CellPoint.subscribe((msg) => {
    (document.querySelector('.cc-done')).style.display = "block";
    (document.querySelector('.cc-loading')).style.display = "none";
    (document.querySelector('.cc-form')).style.display = "none";
    (document.querySelector('.cc-error')).style.display = "none";
  }, CellPoint.messages.STATUS_DONE);

  CellPoint.subscribe((msg) => {
    (document.querySelector('.cc-loading')).style.display = "block";
    (document.querySelector('.cc-form')).style.display = "none";
    (document.querySelector('.cc-error')).style.display = "none";
  }, CellPoint.messages.SUBMITTED);

  CellPoint.subscribe((msg) => {
    console.log("Form error!", msg)
    (document.querySelector('.cc-loading')).style.display = "none";
    (document.querySelector('.cc-error')).style.display = "block";
    (document.querySelector('.cc-form')).style.display = "block";
    (document.querySelector('.cc-error')).style.display = "block";
  }, CellPoint.messages.VALIDATION_ERROR);

  CellPoint.renderField(CellPoint.fields.name, { ...config, label: "Cardholder Name" }, document.getElementById('card-name'));
  CellPoint.renderField(CellPoint.fields.number, { ...config, label: "Card Number" }, document.getElementById('card-number'));
  CellPoint.renderField(CellPoint.fields.cvv, { ...config, label: "CVV" }, document.getElementById('card-cvv'));
  CellPoint.renderField(CellPoint.fields.expirationDate, { ...config, label: "MM/YY" }, document.getElementById('card-expiration-date'));

  CellPoint.renderField(
    CellPoint.fields.submit,
    {
      ...config,
      label: "Click here to pay!",
      styles: {
        input: {
          ...config.styles.input,
          fontWeight: 'bold',
          color: "rgba(0,0,0, 0.5)"
        },
        ':hover': {
          color: "rgba(0,0,0, 1)"
        }
      }
    },
    document.getElementById('card-submit-btn'));
});
